import React from 'react';

function Photos () {
  return (
  <section class="content-section" id="portfolio">
    
  </section>
  )
}

export default Photos